<template>
	<div>
		<div class="page-title">
			<a>
				<router-link :to="{name:'unit/index'}">
					<el-button size="mini" icon="el-icon-back" class="mr-3">返回</el-button>
				</router-link>
			</a>
			<span style="font-size: 20px;">添加单位</span>
		</div>
		
		<div class="bg-white p-3 m-2">
			<el-form ref="form" :rules="rules" :model="form" label-width="100px" style="padding-right: 50%;">
				<el-form-item label="单位名称" prop="unit_name">
					<el-input v-model="form.unit_name" placeholder="名称"></el-input>
				</el-form-item>
				<el-form-item label="上级单位">
					<el-select v-model="form.parent_id" placeholder="请选择上级单位" size="mini">
						<el-option label="顶级单位" :value="0"></el-option>
						<template v-for="item in lists">
							<el-option :label="item.unit_name" :value="item.unit_id"></el-option>
							<template v-for="two in item.child">
								<el-option :label="'----'+two.unit_name" :value="two.unit_id"></el-option>
							</template>
						</template>
					</el-select>
				</el-form-item>
				<el-form-item prop="sort">
					<template slot="label">
						<span>排序 </span>
						<el-tooltip effect="dark" placement="top" content="数字越小越靠前">
							<i class="el-icon-info"></i>
						</el-tooltip>
					</template>
					<el-input-number v-model="form.sort" placeholder="排序"></el-input-number>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit('form')">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				lists: [],
				form: {
					unit_name: '',
					parent_id: 0,
					sort: 100,
				},
				rules: {
					unit_name: [{
							required: true,
							message: '请输入单位名称',
							trigger: 'change'
						},
						{
							max: 100,
							message: '最多输入20个字符',
							trigger: 'change'
						},
					],
					sort: [{
						required: true,
						message: '请输入单位排序',
						trigger: 'change'
					}, ],
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						this.axios({
							token: true,
							params: {
								s: 'store/unit/add',
							},
							data:{
								unit: this.form
							},
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'unit/index'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},
		},
	}
</script>

<style>
</style>
